import { DatePicker, Form } from 'antd'
import React, { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { EVENTS, EVENT_COUNT } from '../graphql/events.graphql'

import Spinner from '../components/common/Spinner'

const { RangePicker } = DatePicker
const { Item } = Form

const updateReportsFormItemLayout = {
  labelCol: {
    // 1600px ≤ width
    xxl: {
      span: 6,
    },
    // 1200px ≤ width < 1600px
    xl: {
      span: 6,
    },
    // 992px ≤ width < 1200px
    lg: {
      span: 8,
    },
    // 768px ≤ width < 992px
    md: {
      span: 10,
    },
    // 576px ≤ width < 768px
    sm: {
      span: 16,
    },
    // width < 576px and also default setting
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    xxl: {
      span: 18,
    },
    xl: {
      span: 18,
    },
    lg: {
      span: 16,
    },
    md: {
      span: 14,
    },
    sm: {
      span: 8,
    },
    xs: {
      span: 24,
    },
  },
}

const countNewAdditions = (events) => {
  const newAdditions = new Set()
  events.forEach((event) => {
    const data = JSON.parse(event.data)
    if (event.type.includes('add')) {
      newAdditions.add(data.id)
    }
    if (event.type.includes('delete')) {
      newAdditions.delete(data.id)
    }
  })
  return newAdditions.size
}

const ReportsPage = () => {
  const [displaySpinner, setDisplaySpinner] = useState(false)
  const [beginningMoment, setBeginningMoment] = useState(null)
  const [endMoment, setEndMoment] = useState(null)

  const [accountsCreatedOrDeleted, setAccountsCreatedOrDeleted] = useState([])
  const [propertiesCreatedOrDeleted, setPropertiesCreatedOrDeleted] = useState(
    []
  )
  const [buildingsCreatedOrDeleted, setBuildingsCreatedOrDeleted] = useState([])
  const [roomsCreatedOrDeleted, setRoomsCreatedOrDeleted] = useState([])
  const [newThumbnailUploads, setNewThumbnailUploads] = useState(0)
  const [newBaseImageAndLayerUploads, setNewBaseImageAndLayerUploads] =
    useState(0)
  const [brochureDownloads, setBrochureDownloads] = useState(0)
  const [cartBrochureDownloads, setCartBrochureDownloads] = useState(0)
  const [integrationEndpointRequests, setIntegrationEndpointRequests] =
    useState(0)

  const momentLongFormat = 'MMM DD, YYYY h:mm:ss A (UTCZ)'

  const [loadEvents] = useLazyQuery(EVENTS)
  const [loadEventCount] = useLazyQuery(EVENT_COUNT)

  const fetchEvents = async (types) => {
    return loadEvents({
      variables: {
        types,
        beginning: beginningMoment ? beginningMoment.toDate().getTime() : 0,
        end: endMoment ? endMoment.toDate().getTime() : 0,
      },
    })
      .then(({ data }) => data)
      .then(({ events }) => events)
  }

  const fetchCountEvents = async (types) => {
    return loadEventCount({
      variables: {
        types,
        beginning: beginningMoment ? beginningMoment.toDate().getTime() : 0,
        end: endMoment ? endMoment.toDate().getTime() : 0,
      },
    })
      .then(({ data }) => data)
      .then(({ eventCount }) => eventCount)
  }

  const fetchStatistics = async () => {
    const accountEvents = await fetchEvents(['add account', 'delete account'])
    const propertyEvents = await fetchEvents([
      'add property',
      'delete property',
    ])
    const buildingEvents = await fetchEvents([
      'add building',
      'delete building',
    ])
    const roomsEvents = await fetchEvents(['add room', 'delete room'])
    const thumbnailEvents = await fetchCountEvents([
      'upload view thumbnail original',
    ])
    const imageAndLayerEvents = await fetchCountEvents([
      'upload view base image original',
      'upload layer original',
    ])
    const brochureDownloadEvents = await fetchCountEvents([
      'track brochure download',
    ])
    const cartBrochureDownloadEvents = await fetchCountEvents([
      'track cart brochure download',
    ])
    const endpointEvents = await fetchCountEvents([
      'send hoa data to integration endpoint',
    ])

    setAccountsCreatedOrDeleted(accountEvents)
    setPropertiesCreatedOrDeleted(propertyEvents)
    setBuildingsCreatedOrDeleted(buildingEvents)
    setRoomsCreatedOrDeleted(roomsEvents)
    setNewThumbnailUploads(thumbnailEvents)
    setNewBaseImageAndLayerUploads(imageAndLayerEvents)
    setBrochureDownloads(brochureDownloadEvents)
    setCartBrochureDownloads(cartBrochureDownloadEvents)
    setIntegrationEndpointRequests(endpointEvents)
  }

  const updateStatistics = async () => {
    setDisplaySpinner(true)
    await fetchStatistics()
    setDisplaySpinner(false)
  }

  useEffect(() => {
    updateStatistics()
  }, [beginningMoment, endMoment])

  return (
    <React.Fragment>
      <Form>
        <Item {...updateReportsFormItemLayout} label="Select Date">
          <RangePicker
            defaultValue={[beginningMoment, endMoment]}
            onChange={([newBeginningMoment, newEndMoment]) => {
              setBeginningMoment(newBeginningMoment.startOf('day'))
              setEndMoment(newEndMoment.endOf('day'))
            }}
          />
        </Item>
        {beginningMoment && endMoment && (
          <React.Fragment>
            <Item {...updateReportsFormItemLayout} label="Report start">
              <span className="ant-form-text">
                {beginningMoment.format(momentLongFormat)}
              </span>
            </Item>
            <Item {...updateReportsFormItemLayout} label="Report end">
              <span className="ant-form-text">
                {endMoment.format(momentLongFormat)}
              </span>
            </Item>
          </React.Fragment>
        )}
        <hr />
        <div style={{ position: 'relative' }}>
          {displaySpinner && <Spinner />}
          <Item {...updateReportsFormItemLayout} label="Accounts created">
            <span className="ant-form-text">
              {countNewAdditions(accountsCreatedOrDeleted)}
            </span>
          </Item>
          <Item {...updateReportsFormItemLayout} label="Properties created">
            <span className="ant-form-text">
              {countNewAdditions(propertiesCreatedOrDeleted)}
            </span>
          </Item>
          <Item
            {...updateReportsFormItemLayout}
            label="Buildings created (new unique URLs)"
          >
            <span className="ant-form-text">
              {countNewAdditions(buildingsCreatedOrDeleted)}
            </span>
          </Item>
          <Item
            {...updateReportsFormItemLayout}
            label="Rooms created (new unique URLs)"
          >
            <span className="ant-form-text">
              {countNewAdditions(roomsCreatedOrDeleted)}
            </span>
          </Item>
          <Item {...updateReportsFormItemLayout} label="New thumbnail uploads">
            <span className="ant-form-text">{newThumbnailUploads}</span>
          </Item>
          <Item
            {...updateReportsFormItemLayout}
            label="New base image and layer uploads"
          >
            <span className="ant-form-text">{newBaseImageAndLayerUploads}</span>
          </Item>
          <Item
            {...updateReportsFormItemLayout}
            label="Non-cart brochure downloads"
          >
            <span className="ant-form-text">{brochureDownloads}</span>
          </Item>
          <Item
            {...updateReportsFormItemLayout}
            label="Cart brochure downloads"
          >
            <span className="ant-form-text">{cartBrochureDownloads}</span>
          </Item>
          <Item {...updateReportsFormItemLayout} label="Send to Rep requests">
            <span className="ant-form-text">{integrationEndpointRequests}</span>
          </Item>
        </div>
      </Form>
    </React.Fragment>
  )
}

export { ReportsPage }
